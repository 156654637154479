import { Injectable } from '@angular/core';


// Interface
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AppService } from 'app/services/app.service';

@Injectable()
export class MonitoringProvider {
  constructor(public http: HttpClient, private appService: AppService) { }


  public get(): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.MONITORING}?suscription=${this.appService.getViewSuscription()}`);
    
  }
}
